import React from "react";
import { useState, useEffect } from "react";
import s from "../style/home.module.css";
import star from "../assets/svg/Frame 85.svg";
import location from "../assets/svg/location.svg";
import mail from "../assets/svg/sms.svg";
import calendar from "../assets/svg/calendar.svg";
import MainTourBlock from "../components/MainTourBlock";
import balloon from "../assets/svg/Balloon.svg";
import booking from "../assets/images/Booking.Com-logo.png";
import jot from "../assets/images/jot-logo-line-colour.png";
import freetour from "../assets/images/Freetour.com-logo-transparent.png";
import hostelworld from "../assets/images/hostelworld-logo-vector.jpg";
import viator from "../assets/images/viator.png";
import VanillaCalendar from "../components/VanillaCalendar.tsx";
import "../index.css";
import bigtree from "../assets/svg/bigtree.svg";
import Footer from "../components/Footer.jsx";
import Header from "../components/Header.jsx";
import plane from "../assets/svg/plane.svg";
import IncreasingNumber from "../components/IncreasingNumber.jsx";
import TourLoader from "../components/TourLoader.jsx";
import toyota from "../assets/images/toyota.jpg";
import Sponsor from "../components/Sponsor.jsx";
import expedia from "../assets/images/Expedia-Logo.png";
import googleLogo from "../assets/images/Google_Cloud_Platform-Logo.png";
import closeCircle from "../assets/svg/close-circle.svg";

const Home = () => {
  const [interests, setInterests] = useState([]);
  const [datesString, setDatesString] = useState("Select dates");
  const [dates, setDates] = useState([]);
  const [calendarShouldShow, setCalendarShouldShow] = useState(false);
  const [destination, setDestination] = useState("");
  const [interestsSelected, setInterestsSelected] = useState([]);
  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [generatedTripsNumber, setGeneratedTripsNumber] = useState(0);
  const [destinationsNumber, setDestinationsNumber] = useState(0);
  const [subscribeTreeMail, setSubscribeTreeMail] = useState("");
  const [subscribeVisible, setSubscribeVisible] = useState(false);
  const [subscribeVisibleExists, setSubscribeVisibleExists] = useState(false);
  const [currentUrl, setCurrentUrl] = useState("");
  const [subscribeError, setSubscribeError] = useState(false);
  const [fiveTours, setFiveTours] = useState([]);
  const [generateErrorPopup, setGenerateErrorPopup] = useState(false);

  // get last five tours
  useEffect(() => {
    const api = `${process.env.REACT_APP_API_URL}/getFiveTours`;
    console.log(api);
    fetch(api)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setFiveTours(data);
      });
  }, []);

  useEffect(() => {
    const api = `${process.env.REACT_APP_API_URL}/getTotalTrips`;
    fetch(api)
      .then((response) => response.json())
      .then((data) => {
        setGeneratedTripsNumber(data[0].total_trips);
      });

    const api2 = `${process.env.REACT_APP_API_URL}/getTotalDestinations`;
    fetch(api2)
      .then((response) => response.json())
      .then((data) => {
        setDestinationsNumber(data.total_destinations);
      });
  }, []);

  const [calendarConfig, setCalendarConfig] = useState({
    type: "multiple",
    months: "2",
    date: {
      min: new Date(),
    },
    settings: {
      visibility: {
        theme: "light",
      },
      range: {
        disablePast: true,
      },
      selection: {
        day: "multiple-ranged",
      },
    },
    actions: {
      clickDay: (e, self) => {
        const startDate = self["selectedDates"][0];
        const endDate = self["selectedDates"][self["selectedDates"].length - 1];
        localStorage.setItem("startDate", startDate);
        localStorage.setItem("endDate", endDate);
      },
    },
  });

  useEffect(() => {
    const updateCalendarMonths = () => {
      if (window.innerWidth < 600) {
        setCalendarConfig((prevConfig) => ({
          ...prevConfig,
          months: "1",
        }));
      } else {
        setCalendarConfig((prevConfig) => ({
          ...prevConfig,
          months: "2",
        }));
      }
    };

    // Initial check
    updateCalendarMonths();

    // Add event listener for window resize
    window.addEventListener("resize", updateCalendarMonths);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateCalendarMonths);
    };
  }, []);

  useEffect(() => {
    const url = `${process.env.REACT_APP_API_URL}/getHomeTexts`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        setLoaded(true);
      });
  }, []);

  function generateUnqiueID() {
    const timestamp = new Date().getTime(); // Current timestamp
    const randomNum = Math.floor(Math.random() * 1000000); // Random number
    const uniqueString = `${timestamp}${randomNum}`;
    return uniqueString;
  }

  function formatDate(dateStr) {
    const date = new Date(dateStr);
    if (isNaN(date)) {
      return "Invalid Date";
    }
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const monthAbbr = months[date.getMonth()];
    const day = date.getDate();
    return `${monthAbbr} ${day}`;
  }

  useEffect(() => {
    const api = `${process.env.REACT_APP_API_URL}/getInterests`;

    fetch(api)
      .then((response) => response.json())
      .then((data) => {
        let array = [];
        for (let i = 0; i < data.length; i++) {
          array.push(data[i].title);
        }
        setInterests(array);
      });
  }, []);

  function handleInterestClick(event) {
    event.target.classList.toggle(s.interestButtonSelected);
    setInterestsSelected([...interestsSelected, event.target.innerText]);
  }

  useEffect(() => {
    const currentUrlTemp = window.location.origin;
    setCurrentUrl(currentUrlTemp);
  }, []);

  function setDatesClick() {
    if (localStorage.getItem("startDate") && localStorage.getItem("endDate")) {
      const startDate = localStorage.getItem("startDate");
      const endDate = localStorage.getItem("endDate");
      const differenceInMs = endDate - startDate;
      const millisecondsPerDay = 1000 * 60 * 60 * 24;
      const differenceInDays = differenceInMs / millisecondsPerDay;
      if (differenceInDays + 1 >= 14) {
        alert("Please select dates that are less than 14 days");
        return;
      } else {
        setDatesString(formatDate(startDate) + " - " + formatDate(endDate));
        setDates([startDate, endDate]);
        setCalendarShouldShow(!calendarShouldShow);
      }
    } else {
      alert("Please select dates");
    }
  }

  function handleCalendarClick(event) {
    if (calendarShouldShow) {
      if (event.target.className === s.datePickerDiv) {
        setCalendarShouldShow(!calendarShouldShow);
      }
    } else {
      setCalendarShouldShow(!calendarShouldShow);
    }
  }

  function handleDestinationChange(event) {
    const input = event.target.value;
    const filteredInput = input.replace(/[^a-zA-Z\s]/g, "");
    setDestination(filteredInput);
  }

  function handleKeyPress(event) {
    const regex = /^[a-zA-Z\s]$/;
    if (!regex.test(event.key)) {
      event.preventDefault();
    }
  }

  function preventMail(event) {
    const regex = /^[a-zA-Z0-9@.]$/;
    if (!regex.test(event.key)) {
      event.preventDefault();
    }
  }

  function generateTour() {
    if (destination === "" || dates.length === 0) {
      setGenerateErrorPopup(true);
      return;
    }
    const uniqueID = generateUnqiueID();
    const startDate = dates[0];
    const toDate = dates[1];
    window.location.href = `/tour?uniqueID=${uniqueID}&destination=${destination}&startDate=${startDate}&endDate=${toDate}&interests=${interestsSelected}`;
  }

  function subscribeTree() {
    if (subscribeTreeMail === "") {
      setSubscribeError(true);
      return;
    }
    const api = `${process.env.REACT_APP_API_URL}/subscribe`;
    const postData = {
      email: subscribeTreeMail,
      type: "tree",
    };

    fetch(api, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message === "subscribed") {
          setSubscribeVisible(true);
        } else {
          setSubscribeVisibleExists(true);
        }
      });
  }

  function calculateDurationLength(startDate, finishDate) {
    const start = new Date(startDate);
    const finish = new Date(finishDate);
    const differenceInTime = finish.getTime() - start.getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);
    return differenceInDays === 0 ? 1 : differenceInDays + 1;
  }

  return (
    <div>
      {subscribeVisible && (
        <div className={s.subscribePopupWrapper}>
          <div className={s.subscribeInnerWrapper}>
            <img
              onClick={() => {
                setSubscribeVisible(false);
              }}
              src={closeCircle}
            />
            <h1>Thank You for Subscribing</h1>
            <p>
              We're thrilled to have you join our community. Stay tuned for the
              latest updates, exclusive offers, and more straight to your inbox.
            </p>
            <p>
              Feel free to explore and enjoy our content. If you have any
              questions, don't hesitate to reach out!
            </p>
          </div>
        </div>
      )}
      {subscribeError && (
        <div className={s.subscribePopupWrapper}>
          <div className={s.subscribeInnerWrapper}>
            <img
              onClick={() => {
                setSubscribeError(false);
              }}
              src={closeCircle}
            />
            <h1>Error While Subscribing</h1>
            <p>Please fill in your email address to subscribe</p>
          </div>
        </div>
      )}
      {subscribeVisibleExists && (
        <div className={s.subscribePopupWrapper}>
          <div className={s.subscribeInnerWrapper}>
            <img
              onClick={() => {
                setSubscribeVisibleExists(false);
              }}
              src={closeCircle}
            />
            <h1>Thank You for Subscribing</h1>
            <p>You have already subscribed with this email</p>
          </div>
        </div>
      )}
      {generateErrorPopup && (
        <div className={s.subscribePopupWrapper}>
          <div className={s.subscribeInnerWrapper}>
            <img
              onClick={() => {
                setGenerateErrorPopup(false);
              }}
              src={closeCircle}
            />
            <h1>Error While Generating trip</h1>
            <p>Please fill in all required fields</p>
          </div>
        </div>
      )}
      {loaded ? (
        <div className={s.pageWrapper}>
          <div className={s.heroDiv}>
            {calendarShouldShow ? (
              <div className={s.datePickerDiv} onClick={handleCalendarClick}>
                <VanillaCalendar config={calendarConfig} />
                <button onClick={setDatesClick} className={s.saveDatesButton}>
                  Save dates
                </button>
              </div>
            ) : (
              <></>
            )}

            <Header />

            <div className={s.heroTextDiv}>
              <h1>{data.title}</h1>
              <h2>{data.subtitle}</h2>
              <p>What are you up to?</p>
            </div>

            <div className={s.inputDiv}>
              <div className={s.inputOutterDiv}>
                <div className={s.inputInnerDiv}>
                  <div className={s.destinationInputDiv}>
                    <img
                      src={location}
                      alt="location"
                      className={s.destinationIcon}
                    />
                    <input
                      type="text"
                      placeholder="Your destination"
                      className={s.destinationInput}
                      onChange={handleDestinationChange}
                      onKeyPress={handleKeyPress}
                    />
                  </div>
                  <div className={s.destinationCalendarDiv}>
                    <img
                      src={calendar}
                      alt="calendar"
                      className={s.calendarIcon}
                    />
                    <button
                      className={s.selectDatesButton}
                      onClick={handleCalendarClick}
                    >
                      {datesString}
                    </button>
                  </div>
                  <div
                    onClick={generateTour}
                    className={s.generateButton}
                    id="tripgenerationbutton"
                  >
                    <img src={star} alt="star" id="tripgenerationbutton" />
                    <p id="tripgenerationbutton">Plan a trip</p>
                  </div>
                </div>
              </div>
            </div>

            <div className={s.interestsDiv}>
              <div className={s.interestsWrapper}>
                {interests.map((interest) => (
                  <button
                    onClick={handleInterestClick}
                    className={s.interestButton}
                  >
                    {interest}
                  </button>
                ))}
              </div>
            </div>

            <div className={s.heroFooterDiv}>
              {window.innerWidth > 500 ? (
                <ul>
                  {data["benefits"].split(";").map((benefit, index) => (
                    <li key={index}>{benefit}</li>
                  ))}
                </ul>
              ) : (
                <div className={s.mobileFooter}>
                  {data["benefits"]
                    .split(";")
                    .reduce((resultArray, item, index) => {
                      const chunkIndex = Math.floor(index / 2);
                      if (!resultArray[chunkIndex]) {
                        resultArray[chunkIndex] = []; // start a new chunk
                      }
                      resultArray[chunkIndex].push(item);
                      return resultArray;
                    }, [])
                    .map((pair, index) => (
                      <ul key={index}>
                        {pair.map((benefit, i) => (
                          <li key={i}>{benefit}</li>
                        ))}
                      </ul>
                    ))}
                </div>
              )}
            </div>
          </div>

          <div className={s.plantTreeDiv}>
            <div className={s.plantTreeLeftside}>
              <h1 className={s.treeTitle}>{data["tree title"]}</h1>
              <h2 className={s.treeDesc}>{data["tree description"]}</h2>
              <h2 className={s.treeDesc2}>Subscribe to get more info</h2>
              <div className={s.plantTreeMailDiv}>
                <img src={mail}></img>
                <input
                  onKeyPress={preventMail}
                  onChange={(e) => setSubscribeTreeMail(e.target.value)}
                  value={subscribeTreeMail} // Bind the value to the state
                  type="text"
                  placeholder="Your Email"
                />
                <button
                  onClick={() => {
                    subscribeTree(); // Call your subscribe function
                    setSubscribeTreeMail(""); // Clear the input field
                  }}
                >
                  Subscribe
                </button>
              </div>
            </div>
            <div className={s.plantTreeDivRightside}>
              <p>{data["tree number"]}</p>
              <p>Carbon absorbed by trees</p>
              <div className={s.treeWrapper}>
                <img className={s.treeSvg} src={bigtree}></img>
              </div>
            </div>
          </div>

          <div className={s.gtDiv}>
            <img src={plane} alt="plane" className={s.plane} />
            <div className={s.gtHeadDiv}>
              <div className={s.gtTitleWrapper}>
                <h1>{data["generated tours title"]}</h1>
              </div>
              <h2 className={s.gtDesc}>
                {data["generated tours description"]}
              </h2>
            </div>

            <div id="generatedtrips" className={s.gtToursDiv}>
              {Array.from({ length: 50 }).map((_, i) =>
                fiveTours.map((tour, index) => {
                  return (
                    <MainTourBlock
                      destination={tour.destination}
                      length={calculateDurationLength(
                        tour.trip_from,
                        tour.trip_to
                      )}
                      img={tour.img}
                      tour={`${currentUrl}/tour?uniqueID=${tour.tour_id}`}
                    />
                  );
                })
              )}
            </div>
          </div>

          <div className={s.numbersDiv}>
            <h1 className={s.numbersTitle}>{data["numbers title"]}</h1>
            <h2 className={s.numbersDesc}>{data["numbers description"]}</h2>
            <div className={s.numbers}>
              <div className={s.scheduled}>
                <IncreasingNumber targetNumber={generatedTripsNumber} />
                <p>Generated Trips</p>
              </div>
              <div className={s.countries}>
                <IncreasingNumber targetNumber={destinationsNumber} />
                <p>Destinations</p>
              </div>
              <div className={s.treesplanted}>
                <IncreasingNumber targetNumber={data["numbers trees number"]} />
                <p>Trees planted</p>
              </div>
            </div>
          </div>

          <div className={s.sustDiv}>
            <div className={s.susDescDiv}>
              <h1 className={s.susTitle}>{data["sustainibility title"]}</h1>
              <p className={s.susDesc}>{data["sustainibility description"]}</p>
            </div>
            <img className={s.balloon1} src={balloon} />
            <img className={s.balloon2} src={balloon} />
            <div className={s.sustcardsWrapper}>
              <div className={`${s.sustCard1} ${s.sustCard}`}>
                <p>SDG 8</p>
                <p>Target: {data["sdg 8 target"]}</p>
                <p>{data["sdg 8 text"]}</p>
              </div>
              <div className={`${s.sustCard3} ${s.sustCard}`}>
                <p>SDG 17</p>
                <p>Target: {data["sdg 17 target"]}</p>
                <p>{data["sdg 17 text"]}</p>
              </div>
              <div className={`${s.sustCard2} ${s.sustCard}`}>
                <p>SDG 13</p>
                <p>Target: {data["sdg 13 target"]}</p>
                <p>{data["sdg 13 text"]}</p>
              </div>
            </div>
          </div>

          <div className={s.sponsorDiv}>
            <Sponsor
              image={booking}
              url="https://www.booking.com/index.html?aid=7905319"
            />
            <Sponsor image={jot} url="https://www.justonetree.life/" />
            <Sponsor
              image={freetour}
              url="https://www.freetour.com/?referralID=6u2reWJP6B8l56Ik&campaign=partner"
            />
            <Sponsor
              image={hostelworld}
              url="https://www.hostelworld.com/?source=affiliate-PHG-1100l3wJYr&affiliate=PHG&ref_id=1100lzyVrZKo&uniqueclickID=1100lzyVrZKo&utm_content="
            />
            <Sponsor
              special="viator"
              image={viator}
              url="https://www.viator.com/?pid=P00148393&mcid=42383&medium=link&campaign=partners"
            />
            <Sponsor
              special="toyota"
              image={toyota}
              url="https://www.toyota.ge/"
            />
            <Sponsor
              special="expedia"
              image={expedia}
              url="https://expedia.com/affiliate/uWlyoKX/"
            />
            <Sponsor image={googleLogo} url="https://cloud.google.com/" />

            <Sponsor
              image={booking}
              url="https://www.booking.com/index.html?aid=7905319"
            />
            <Sponsor image={jot} url="https://www.justonetree.life/" />
            <Sponsor
              image={freetour}
              url="https://www.freetour.com/?referralID=6u2reWJP6B8l56Ik&campaign=partner"
            />
            <Sponsor
              image={hostelworld}
              url="https://www.hostelworld.com/?source=affiliate-PHG-1100l3wJYr&affiliate=PHG&ref_id=1100lzyVrZKo&uniqueclickID=1100lzyVrZKo&utm_content="
            />
            <Sponsor
              image={viator}
              url="https://www.viator.com/?pid=P00148393&mcid=42383&medium=link&campaign=partners"
            />
            <Sponsor
              special="toyota"
              image={toyota}
              url="https://www.toyota.ge/"
            />
            <Sponsor
              image={expedia}
              url="https://expedia.com/affiliate/uWlyoKX/"
            />
            <Sponsor image={googleLogo} url="https://cloud.google.com/" />
          </div>

          <Footer
            setSubscribeVisible={setSubscribeVisible}
            setSubscribeVisibleExists={setSubscribeVisibleExists}
          />
        </div>
      ) : (
        <TourLoader />
      )}
      ;
    </div>
  );
};

export default Home;
