import "../style/footerpages.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import React, { useState, useEffect } from "react";

const Terms = () => {
  const [html, setHtml] = useState("");

  useEffect(() => {
    const api = `${process.env.REACT_APP_API_URL}/getSecondaryPage?page=terms`;
    fetch(api)
      .then((response) => response.json())
      .then((data) => {
        setHtml(data[0].terms);
      });
  });
  return (
    <div>
      <div className="hero">
        <Header />
        <p className="firstText">Terms and Conditions</p>
        {/* <img className="backgroundImg" src={background} /> */}
      </div>

      <div className="body">
        <div dangerouslySetInnerHTML={{ __html: html }}></div>
      </div>

      <Footer />
    </div>
  );
};

export default Terms;
