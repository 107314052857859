import React, { useEffect, useState } from "react";
import triggerTourGeneration from "../javascript/triggerTourGeneration";
import { Element, scroller } from "react-scroll";
import Day from "../components/Day";
import s from "../style/tour.module.css";
import dateToString from "../javascript/DateToString";
import Leaf from "../components/Leaf";
import send from "../assets/svg/send-2.svg";
import heart from "../assets/svg/heart.svg";
import map from "../assets/svg/map.svg";
import getDaysDifference from "../javascript/getDaysDifference";
import TourLoader from "../components/TourLoader";
import exclamation from "../assets/svg/exclamation.svg";
import free from "../assets/svg/Freetour.svg";
import booking from "../assets/images/booking.png";
import hostelWorld from "../assets/images/hostelworld-logo-vector.jpg";
import star from "../assets/svg/star_yellow.svg";
import Balloon from "../assets/svg/Balloon.svg";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { IoMdClose } from "react-icons/io";
import logo from "../assets/svg/logo.svg";
import user from "../assets/svg/userblue.svg";
import disk from "../assets/svg/heartyellow.svg";
import leaf from "../assets/svg/leafgreen.svg";
import edit from "../assets/svg/editorange.svg";
import { useGoogleLogin } from "@react-oauth/google";
import login from "../javascript/Login";
import top from "../assets/svg/top.svg";
import plane from "../assets/svg/airplane.svg";
import closeCircle from "../assets/svg/close-circle.svg";

const Tour = () => {
  const [uniqueId, setUniqueId] = useState(null);
  const [destination, setDestination] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [interests, setInterests] = useState(["not specific"]);
  const [mail, setMail] = useState(null);
  const [username, setUsername] = useState(null);
  const [tourData, setTourData] = useState([]);
  const [tourLength, setTourLength] = useState(1);
  const [freeTourData, setFreeTourData] = useState([]);
  const [freeTourLinks, setFreeTourLinks] = useState([]);
  const [freeTourPics, setFreeTourPics] = useState([]);
  const [freeTourTitles, setFreeTourTitles] = useState([]);
  const [hostelWorldUrl, setHostelWorldUrl] = useState("");
  const [ratingShouldBeVisible, setRatingShouldBeVisible] = useState(false);
  const [dayNames, setDayNames] = useState([]);
  const [isSaved, setIsSaved] = useState(false);
  const [isLogged, setIsLogged] = useState(false);
  const [regenerateInterests, setRegenerateInterests] = useState([]);
  const [interestsSelected, setInterestsSelected] = useState([]);
  const [regenerateVisible, setRegenerateVisible] = useState(false);
  const [editDayNum, setEditDayNum] = useState(null);
  const [isRegenerating, setIsRegenerating] = useState(false);
  const [freeDoesNotExist, setFreeDoesNotExist] = useState(false);
  const [submitVisible, setSubmitVisible] = useState(true);
  const [currentRating, setCurrentRating] = useState(0);
  const [aiDestination, setAiDestination] = useState("");
  const [logInFirst, setLogInFirst] = useState(false);

  useEffect(() => {
    document.title = `${tourLength} days trip to ${destination}`;
    if (tourData[0]) {
      setAiDestination(tourData[0][0].destination);
    }
    if (aiDestination.length > 0) {
      document.title = `${tourLength} days trip to ${aiDestination}`;
    }
  }, [tourLength, destination, tourData, aiDestination]);

  useEffect(() => {
    const mail = localStorage.getItem("email");
    const url = `${process.env.REACT_APP_API_URL}/checkSaved?mail=${mail}&tourID=${uniqueId}`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (data.message === "tour saved") {
          setIsSaved(true);
        } else {
          setIsSaved(false);
        }
      });
  }, [uniqueId, mail, isLogged]);

  const saveTrip = () => {
    if (!isLogged) {
      setLogInFirst(true);
      return;
    }

    const mail = localStorage.getItem("email");
    const name = localStorage.getItem("name");
    const tour = window.location.href;
    const tourID = uniqueId;
    const destination = tourData[0][0].destination;

    const url = `${
      process.env.REACT_APP_API_URL
    }/saveTrip?mail=${mail}&name=${name}&tour=${encodeURIComponent(
      tour
    )}&tourID=${tourID}&destination=${destination}`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setIsSaved(true);
      });
  };
  useEffect(() => {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const start = url.searchParams.get("startDate");
    const end = url.searchParams.get("endDate");
    const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const result = [];
    const startDate = new Date(start);
    const endDate = new Date(end);
    for (
      let date = new Date(startDate);
      date <= endDate;
      date.setDate(date.getDate() + 1)
    ) {
      result.push(dayNames[date.getDay()]);
    }
    setDayNames(result);
  }, []);

  useEffect(() => {
    if (!tourData[0]) {
      return;
    }
    let country = tourData[0][0].destination;
    if (country.includes(",")) {
      country = country.split(",")[1].trim();
    }
    const url = `${process.env.REACT_APP_API_URL}/freeTour?country=${country}&uniqueID=${uniqueId}`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (!data.data) {
          setFreeDoesNotExist(true);
          return;
        }
        setFreeTourData(data.data);
        setFreeTourLinks(data.data[0].freetour_links.split("../.."));
        setFreeTourPics(data.data[0].freetour_pics.split("../.."));
        setFreeTourTitles(data.data[0].freetour_titles.split("../.."));
      });
  }, [tourData, uniqueId]);

  useEffect(() => {
    if (!freeTourData) {
      return;
    }
  }, [freeTourData]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    setUniqueId(searchParams.get("uniqueID"));
    setDestination(searchParams.get("destination"));
    setStartDate(searchParams.get("startDate"));
    setEndDate(searchParams.get("endDate"));
    const tempInterests = searchParams.get("interests")?.split(",");
    setInterests(tempInterests);
    const tempMail = localStorage.getItem("email") || "";
    setMail(tempMail);
    const tempUsername = localStorage.getItem("name") || "";
    setUsername(tempUsername);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const tourLengthThis = getDaysDifference(startDate, endDate);
      setTourLength(tourLengthThis);
      try {
        for (let i = 1; i <= tourLengthThis; i++) {
          let jsonData;
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/getData?uniqueID=${uniqueId}&dayNum=${i}`
          );
          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }
          jsonData = await response.json();
          setTourData((prevState) => [...prevState, jsonData[`day${i}`]]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (uniqueId) {
      triggerTourGeneration(
        uniqueId,
        destination,
        startDate,
        endDate,
        interests,
        mail,
        username
      );
      fetchData();
    } else {
    }
  }, [uniqueId]);

  useEffect(() => {
    const handleScroll = (event) => {
      if (event.deltaY > 0) {
        scrollToNext();
      } else {
        scrollToPrevious();
      }
    };

    // window.addEventListener("wheel", handleScroll);

    return () => {
      // window.removeEventListener("wheel", handleScroll);
    };
  }, [tourData]);

  const scrollToNext = () => {
    const currentElement = document.querySelector(".active");
    if (currentElement) {
      if (currentElement.id === "heroDiv") {
        scroller.scrollTo(`day-0`, {
          duration: 500,
          smooth: true,
        });
        currentElement.classList.remove("active");
        document.getElementById("day-0").classList.add("active");
      } else if (currentElement.nextElementSibling) {
        scroller.scrollTo(currentElement.nextElementSibling.id, {
          duration: 500,
          smooth: true,
        });
        currentElement.classList.remove("active");
        currentElement.nextElementSibling.classList.add("active");
      } else if (
        !currentElement.nextElementSibling &&
        currentElement.id !== "freeTourWrapper"
      ) {
        scroller.scrollTo("freeTourWrapper", {
          duration: 500,
          smooth: true,
        });
        currentElement.classList.remove("active");
        const freetour = document.getElementById("freeTourWrapper");
        if (freetour) {
          freetour.classList.add("active");
        }
      }
    }
  };

  const scrollToPrevious = () => {
    const currentElement = document.querySelector(".active");
    if (currentElement) {
      if (currentElement.id === "freeTourWrapper") {
        const lastDayId = `day-${tourData.length - 1}`;
        scroller.scrollTo(lastDayId, {
          duration: 500,
          smooth: true,
        });
        currentElement.classList.remove("active");
        document.getElementById(lastDayId).classList.add("active");
      } else if (currentElement.id === "day-0") {
        scroller.scrollTo("heroDiv", {
          duration: 500,
          smooth: true,
        });
        currentElement.classList.remove("active");
        document.getElementById("heroDiv").classList.add("active");
      } else if (currentElement.previousElementSibling) {
        scroller.scrollTo(currentElement.previousElementSibling.id, {
          duration: 500,
          smooth: true,
        });
        currentElement.classList.remove("active");
        currentElement.previousElementSibling.classList.add("active");
      }
    }
  };

  function BookingCity(city, startDate, endDate) {
    let link1 = `https://www.booking.com/searchresults.en-gb.html?aid=7905319&checkin=${startDate}&checkout=${endDate}&class_interval=1&group_adults=2&group_children=0&keep_landing=1&label_click=undef&no_rooms=1&room1=A%2CA&sb_price_type=total&shw_aparth=1&slp_r_match=0&ss=${city}&ssb=empty&nflt=SustainablePropertyLevelFilter%3D4`;
    return link1;
  }

  function BookingCityLink(city) {
    const queryParameters = new URLSearchParams(window.location.search);
    const startDate = queryParameters.get("startDate");
    const endDate = queryParameters.get("endDate");
    const link = BookingCity(city, startDate, endDate);
    // return (
    //   <p className={s.hotel_text}>
    //     Find sustainable hotels in{" "}
    //     <a className={s.hotel_link} href={link} target="_blank" rel="noreferrer">
    //       {city}
    //     </a>
    //   </p>
    // );
    return link;
  }

  useEffect(() => {
    async function getHostelworldUrl() {
      if (!tourData[0]) {
        return;
      }
      try {
        const country = tourData[0][0].destination.split(",")[0].trim();
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/hostelworld?destination=${country}`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        const url = `https://hostelworld.prf.hn/click/camref:1100l3wJYr/destination:https%3A%2F%2Fwww.hostelworld.com%2Fpwa%2Fwds%2Fs%3Fq%3D${country}%26type%3Dcity%26id%3D${data.id}%26from%3D${startDate}%26to%3D${endDate}%26guests%3D2%26page%3D1%26minSustainabilityTier%3D1`;
        setHostelWorldUrl(url);
      } catch (error) {
        console.error(`Failed to fetch Hostelworld ID`, error);
        return null;
      }
    }
    getHostelworldUrl();
  }, [tourData]);

  useEffect(() => {
    // On component mount or update, color the stars based on currentRating
    for (let i = 1; i <= 5; i++) {
      const starElement = document.getElementById(`star${i}`);
      if (starElement) {
        // Check if the element exists
        if (i <= currentRating) {
          starElement.style.filter = "grayscale(0%)";
        } else {
          starElement.style.filter = "grayscale(100%)";
        }
      }
    }
  }, [currentRating]);

  const rate = (event) => {
    const rating = parseInt(event.target.id.replace("star", "")); // Extract the number from the ID
    setCurrentRating(rating); // Update the state
    for (let i = 1; i <= rating; i++) {
      document.getElementById(`star${i}`).style.filter = "grayscale(0%)";
      document
        .getElementById(`star${i}`)
        .removeEventListener("mouseenter", colorStar);
      document
        .getElementById(`star${i}`)
        .removeEventListener("mouseleave", uncolorStar);
    }
    setSubmitVisible(true); // Show submit button if necessary
  };

  const handleRateSubmit = () => {
    const url = `${process.env.REACT_APP_API_URL}/rating?rating=${currentRating}&tourID=${uniqueId}`;
    fetch(url);
    setSubmitVisible(false); // Hide submit button after submission
  };

  const colorStar = (event) => {
    const id = parseInt(event.target.id.replace("star", "")); // Extract the number from the ID
    for (let i = 1; i <= id; i++) {
      document.getElementById(`star${i}`).style.filter = "grayscale(0%)";
    }
  };

  const uncolorStar = () => {
    for (let i = 1; i <= 5; i++) {
      // Assuming you have 5 stars
      if (i > currentRating) {
        document.getElementById(`star${i}`).style.filter = "grayscale(100%)";
      } else {
        document.getElementById(`star${i}`).style.filter = "grayscale(0%)";
      }
    }
  };

  // Adding event listeners to the stars
  document.querySelectorAll(".star").forEach((star) => {
    star.addEventListener("click", rate);
    star.addEventListener("mouseenter", colorStar);
    star.addEventListener("mouseleave", uncolorStar);
  });

  useEffect(() => {
    let checkrating = `${process.env.REACT_APP_API_URL}/getRating?tourID=${uniqueId}`;
    fetch(checkrating)
      .then((response) => {
        if (!response.ok) {
          throw new Error("networ response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (data[0]) {
          if (data[0].rating != null) {
            setRatingShouldBeVisible(false);
          } else {
            setRatingShouldBeVisible(true);
          }
        }
      });
  }, [uniqueId]);

  const StarRating = () => {
    const stars = Array(5).fill(0); // Create an array with 5 elements
    return (
      <div className={s.stars}>
        {stars.map((_, index) => (
          <img
            key={index}
            onClick={rate}
            onMouseEnter={colorStar}
            onMouseLeave={uncolorStar}
            id={`star${index + 1}`}
            className={s.star}
            src={star}
            alt={`Star ${index + 1}`}
          />
        ))}
      </div>
    );
  };

  const scrollToDay = (index) => {
    if (index === "freeTourWrapper") {
      scroller.scrollTo("freeTourWrapper", {
        duration: 500,
        smooth: true,
      });
      return;
    } else {
      scroller.scrollTo(`day-${index}`, {
        duration: 500,
        smooth: true,
      });
    }
  };

  useEffect(() => {
    const api = `${process.env.REACT_APP_API_URL}/getInterests`;

    fetch(api)
      .then((response) => response.json())
      .then((data) => {
        let array = [];
        for (let i = 0; i < data.length; i++) {
          array.push(data[i].title);
        }
        setRegenerateInterests(array);
      });
  }, []);

  function handleInterestClick(event) {
    event.target.classList.toggle(s.interestButtonSelected);
    setInterestsSelected([...interestsSelected, event.target.innerText]);
  }

  function regenerateDay() {
    setRegenerateVisible(false);
    const url = `${process.env.REACT_APP_API_URL}/regenerateDay`;
    const interestsString = interestsSelected.join(", ");
    if (interestsSelected.length === 0) {
      alert("Select at least one interest");
      return;
    }
    setIsRegenerating(true);
    const postData = {
      uniqueID: uniqueId,
      dayNum: `day${editDayNum}`,
      interests: interestsString,
    };
    const replaceAtIndex = (newValue) => {
      const updatedData = [...tourData];
      updatedData[editDayNum - 1] = newValue;
      setTourData(updatedData);
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        replaceAtIndex(data[`day${editDayNum}`]);
        setInterestsSelected([]);
        setIsRegenerating(false);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  }

  const handleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      login(tokenResponse);
      setTimeout(() => {
        setLogInFirst(false);
        setIsLogged(true);
        window.location.reload();
      }, 1000);
    },
  });

  function share() {
    const url = window.location.href;
    navigator.clipboard.writeText(url);
    const copiedDiv = document.getElementById("copieddiv");
    copiedDiv.style.opacity = "100%";
    setTimeout(() => {
      copiedDiv.style.opacity = "0";
    }, 2000);
  }

  return tourData.length === 0 ? (
    <TourLoader text={"Generating sustainable trip to"}/>
  ) : (
    <div className={s.pageWrapper}>
      <Header setIsLogged={setIsLogged} isTour={true} />

      {regenerateVisible &&
        (!isLogged ? (
          <div className={s.regenerateWrapper}>
            <div className={s.regenerateInnerWrapper}>
              <img
                src={closeCircle}
                className={s.closeIcon}
                onClick={() => setRegenerateVisible(false)}
              />
              <p>Modify this day</p>
              <div className={s.interestsDiv}>
                {regenerateInterests.map((interest) => (
                  <button
                    onClick={handleInterestClick}
                    className={s.interestButton}
                  >
                    {interest}
                  </button>
                ))}
              </div>
              <button
                id="confirmmodifyday"
                onClick={regenerateDay}
                className={s.regenerateButton}
              >
                Modify
              </button>
            </div>
          </div>
        ) : (
          <div className={s.regenerateWrapper}>
            <div className={s.regenerateInnerWrapper}>
              <img
                src={closeCircle}
                className={s.closeIcon}
                onClick={() => setRegenerateVisible(false)}
              />
              <div className={s.joinBIlikiDiv}>
                <p>Join</p>
                <img src={logo} alt="logo" className={s.headerLogo} />
              </div>
              <div className={s.singUpPopupDivBody}>
                <p className={s.benefitsP}>Benefits</p>
                <div>
                  <img src={user} />
                  <p>Personalize your trip plan</p>
                </div>
                <div>
                  <img src={disk} />
                  <p>Save trips to your account</p>
                </div>
                <div>
                  <img src={leaf} />
                  <p>
                    Discover your total carbon amission savings across all trips
                  </p>
                </div>
                <div>
                  <img src={edit} />
                  <p>Modify a day upon your interests</p>
                </div>
              </div>
              <button
                id="googleLoginButton"
                onClick={() => handleLogin()}
                className={s.loginButton}
              >
                Sign in
              </button>
            </div>
          </div>
        ))}

      {logInFirst && (
        <div className={s.regenerateWrapper}>
          <div className={s.regenerateInnerWrapper}>
            <img
              src={closeCircle}
              className={s.closeIcon}
              onClick={() => setLogInFirst(false)}
            />
            <div className={s.joinBIlikiDiv}>
              <p>Join</p>
              <img src={logo} alt="logo" className={s.headerLogo} />
            </div>
            <div className={s.singUpPopupDivBody}>
              <p className={s.benefitsP}>Benefits</p>
              <div>
                <img src={user} />
                <p>Personalize your trip plan</p>
              </div>
              <div>
                <img src={disk} />
                <p>Save trips to your account</p>
              </div>
              <div>
                <img src={leaf} />
                <p>
                  Discover your total carbon amission savings across all trips
                </p>
              </div>
              <div>
                <img src={edit} />
                <p>Modify a day upon your interests</p>
              </div>
            </div>
            <button
              id="googleLoginButton"
              onClick={() => handleLogin()}
              className={s.loginButton}
            >
              Sign in
            </button>
          </div>
        </div>
      )}

      <div className={s.backToTopDiv}>
        <img
          src={top}
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        />
      </div>

      <div id="heroDiv" className={s.heroDiv}>
        <img className={s.heroImg} src={`${tourData[0][0].img}`}></img>
        <div className={s.fadeOverlay}></div>
        <div className={s.heroTitleDiv}>
          <h1>{tourData[0][0].destination}</h1>
          <h2>
            {dateToString(tourData[0][0].trip_from)} -{" "}
            {dateToString(tourData[0][0].trip_to)}
          </h2>
        </div>
        <div className={s.line}></div>
        <div className={s.heroBodyDiv}>
          <h1>
            {tourData[0][0].carbon_footprint} MtCO2e{" "}
            <img src={exclamation} className={s.exclamation} />
            <div className={s.hiddenDesc}>
              <p>
                Our AI analyzes your travel plans and calculates the carbon
                savings compared to a regular trip when you follow our
                eco-friendly itinerary.
              </p>
            </div>
          </h1>
          <p>Carbon Footprint Reduction</p>
          <Leaf sustainable={tourData[0][0].sustainable} />
        </div>

        <div className={s.heroButtonsDiv}>
          <button onClick={share} className={s.shareButton}>
            <img src={send} />
            {window.innerWidth > 700 && "Share"}
          </button>
          <div id="copieddiv" className={s.copiedDiv}>
            <p>Copied to clipboard</p>
          </div>
          {isSaved ? (
            <button
              onClick={saveTrip}
              className={`${s.saveButton} ${s.savedButton}`}
            >
              Trip saved
            </button>
          ) : (
            <button onClick={saveTrip} className={s.saveButton}>
              <img src={heart} />
              {window.innerWidth > 700 && "Save"}
            </button>
          )}

          {/* <button className={s.mapButton}>
            <img src={map} />
            Map
          </button> */}

          <button
            id="flights"
            className={s.flightsButton}
            onClick={() => window.open("https://expedia.com/affiliate/vM45tbG")}
          >
            <img src={plane} />
            {window.innerWidth > 700 && "Flights"}
          </button>
        </div>
      </div>

      {tourData.map((data, index) => (
        <>
          <Element
            key={index}
            name={`day-${index}`}
            id={`day-${index}`}
            className={index === 0 ? "section active" : "section"}
            style={{ height: "100%", minHeight: "100vh" }}
          >
            {" "}
            <div>
              <Day
                freeDoesNotExist={freeDoesNotExist}
                isRegenerating={isRegenerating}
                editDayNum={editDayNum}
                setEditDayNum={setEditDayNum}
                setRegenerateVisible={setRegenerateVisible}
                data={data[0]}
                dayIndex={index}
                currentDaysLength={tourData.length}
              />
            </div>
            {tourLength === index + 1 && !freeDoesNotExist && (
              <>
                {/* // <Element name="freeTourWrapper" id="freeTourWrapper" className={s.freeTourWrapper}> */}
                <div className={s.daysList}>
                  {Array.from({ length: tourData.length }).map((_, index) => (
                    <div className={s.dayCircleWrapper}>
                      <div
                        className={s.dayCircle}
                        key={index}
                        onClick={() => scrollToDay(index)}
                      >
                        <p>{index + 1}</p>
                      </div>
                      <p>{dayNames[index]}</p>
                    </div>
                  ))}
                  <div className={s.dayCircleWrapper}>
                    <div
                      onClick={() => scrollToDay("freeTourWrapper")}
                      className={s.dayCircle}
                      style={{ backgroundColor: "#f07a18", border: "none" }}
                    >
                      {/* Add content for the extra item here */}
                      <img src={free} width={20} />
                    </div>
                    <p style={{ color: "rgb(240, 122, 24)" }}>Free</p>
                  </div>
                </div>
                <div id="freeTourWrapper">
                  <div className={s.freeToursHead}>
                    <h1>Book Free Tours!</h1>
                    <h2>
                      Book budget and free tours with local guides in 120+
                      countries
                    </h2>
                  </div>

                  <div className={s.freeToursBody}>
                    {freeTourData && freeTourData.length > 0 ? (
                      <div className={s.viatorDiv}>
                        {freeTourLinks.map((link, index) => (
                          <div className={s.viatorCard}>
                            <div className={s.viatorImageDiv}>
                              <img src={freeTourPics[index]} />
                            </div>
                            <p>{freeTourTitles[index]}</p>
                            <button
                              id="freetoursbooknow"
                              onClick={() => window.open(freeTourLinks[index])}
                            >
                              Book Now
                            </button>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </>
              // </Element>
            )}
          </Element>
          {tourLength === index + 1 && (
            <div className={s.hotelsWrapper}>
              <div className={s.hotelsHead}>
                <h1>Book Sustainable Hotels</h1>
                <h2>
                  Find your Sustainable accommodation. Find budget prices on
                  hotels & hostels
                </h2>
              </div>
              <div className={s.hotelsDiv}>
                <div className={s.hotelCard}>
                  <img src={booking} />
                  <button
                    id="bookingbooknow"
                    onClick={() =>
                      window.open(
                        BookingCityLink(tourData[0][0].destination),
                        "_blank"
                      )
                    }
                  >
                    Book Now
                  </button>
                </div>

                <div className={s.hotelCard}>
                  <img src={hostelWorld} />
                  <button
                    id="hostelworldbooknow"
                    onClick={() => window.open(hostelWorldUrl, "_blank")}
                  >
                    Book Now
                  </button>
                </div>
              </div>
            </div>
          )}

          {tourLength === index + 1 && ratingShouldBeVisible && (
            <div className={s.rateDiv}>
              {submitVisible ? (
                <>
                  <h1>Rate the trip</h1>

                  <StarRating />
                  <button id="ratethetrip" onClick={handleRateSubmit}>
                    Submit
                  </button>
                </>
              ) : (
                <p>Thank you for your feedback!</p>
              )}
              <img src={Balloon} className={s.balloon1} />
              <img src={Balloon} className={s.balloon2} />
            </div>
          )}

          {tourLength === index + 1 && <Footer />}
        </>
      ))}
    </div>
  );
};

export default Tour;
