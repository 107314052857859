
function login(response){

    const url = 'https://www.googleapis.com/oauth2/v1/userinfo?alt=json';
    const accessToken = response.access_token
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    fetch(url, { headers })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch user data');
        }
        return response.json();
      })
      .then(data => {
        localStorage.setItem('id', data.id);
        localStorage.setItem('email', data.email);
        localStorage.setItem('name', data.name);
        localStorage.setItem('logged_in', 'true')
      })
      .catch(error => {
        console.error('Error fetching user data:', error);
        const userInfoDiv = document.getElementById('user-info');
        userInfoDiv.innerHTML = `<p>Error fetching user data</p>`;
      });
}
export default login;