import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the styles
import s from "../../style/admin/pages.module.css";

const Pages = () => {
  const [editorHtml, setEditorHtml] = useState("");
  const [selectedPage, setSelectedPage] = useState("about_us");

  useEffect(() => {
    const api = `${process.env.REACT_APP_API_URL}/getSecondaryPage?page=${selectedPage}`;
    fetch(api)
      .then((response) => response.json())
      .then((data) => {
        setEditorHtml(data[0][selectedPage]);
      });
  }, [selectedPage]);

  function handlePageChange(e) {
    setSelectedPage(e.target.value);
    setEditorHtml("");
  }

  function handleContentSave(html) {
    const api = `${process.env.REACT_APP_API_URL}/saveSecondaryPage`;

    const postdata = {
      page: selectedPage,
      html: html,
    };

    fetch(api, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postdata),
    })
      .then((response) => response.json())
      .then((data) => {
        alert("Content saved successfully");
      });

    // fetch(api, {
    //   method: "GET",
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     alert("Content saved successfully");
    //   });
  }

  // Handle editor changes
  const handleEditorChange = (content) => {
    setEditorHtml(content);
  };

  // Quill toolbar configuration to include only bold control
  const modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }], // Font size options
      [{ bold: true }], // Bold option
    ],
  };
  return (
    <div className={s.wrapper}>
      <h2>Secondary pages editor</h2>

      <select value={selectedPage} onChange={handlePageChange}>
        <option value="about_us">About Us</option>
        <option value="privacy_policy">Privacy Policy</option>
        <option value="terms">Terms</option>
      </select>
      {/* React Quill editor */}
      <ReactQuill
        value={editorHtml}
        onChange={handleEditorChange}
        modules={modules}
        formats={["size", "bold"]} // Enable only size and bold formatting
        className="custom-editor" // Custom class for CSS styling
      />
      <button className={s.save} onClick={() => handleContentSave(editorHtml)}>
        Save HTML
      </button>
    </div>
  );
};

export default Pages;
