import React, { useState } from "react";
import s from "../style/basics/pagination.module.css";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const [goToPage, setGoToPage] = useState("");

  const pageNumbers = [];

  const generatePageNumbers = () => {
    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      pageNumbers.push(1);

      if (currentPage > 3) {
        pageNumbers.push("...");
      }

      for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
        pageNumbers.push(i);
      }

      if (currentPage < totalPages - 2) {
        pageNumbers.push("...");
      }

      pageNumbers.push(totalPages);
    }
  };

  generatePageNumbers();

  const handleGoToPageChange = (e) => {
    setGoToPage(e.target.value);
  };

  const handleGoToPageSubmit = (e) => {
    e.preventDefault();
    const pageNumber = parseInt(goToPage, 10);
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      onPageChange(pageNumber);
    }
    setGoToPage("");
  };

  return (
    <div className={s.pagination}>
      <div>
        <button className={s.navButton} onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1}>
          <MdKeyboardArrowLeft size={35} />
        </button>

        {pageNumbers.map((number, index) =>
          number === "..." ? (
            <span key={index} className="ellipsis">
              ...
            </span>
          ) : (
            <button
              key={index}
              onClick={() => onPageChange(number)}
              className={`${number === currentPage ? s.active : ""} ${s.number}`}
            >
              {number}
            </button>
          )
        )}

        <button
          className={s.navButton}
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <MdOutlineKeyboardArrowRight size={35} />
        </button>
      </div>

      {/* Go to Page Input */}
      <div>
        <form onSubmit={handleGoToPageSubmit} className={s.gotopageform}>
          <input
            type="number"
            value={goToPage}
            onChange={handleGoToPageChange}
            min="1"
            max={totalPages}
            placeholder="Go to page"
            className={s.gotopageinput}
          />
          <button type="submit" className={s.gotopagebutton}>
            Go
          </button>
        </form>
      </div>
    </div>
  );
};

export default Pagination;
