import React, { useState, useEffect } from "react";
import s from "../../style/admin/adminUsers.module.css";
import { IoPersonAddSharp } from "react-icons/io5";
import { FaRegEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import { FaLock } from "react-icons/fa";

const AdminUsers = () => {
  const [admins, setAdmins] = useState([]);
  const [visiblePasswords, setVisiblePasswords] = useState({});
  const [addVisible, setAddVisible] = useState(false);
  const [addError, setAddError] = useState("");
  const [addUsername, setAddUsername] = useState("");
  const [addPassword, setAddPassword] = useState("");
  const [fetchData, setFetchData] = useState(true);

  const [editUsername, setEditUsername] = useState("");
  const [editVisible, setEditVisible] = useState(false);
  const [editError, setEditError] = useState("");
  const [editPassword, setEditPassword] = useState("");

  useEffect(() => {
    const api = `${process.env.REACT_APP_API_URL}/getAdmins`;
    fetch(api)
      .then((response) => response.json())
      .then((data) => {
        setAdmins(data);
        // Initialize the visibility state for each admin password as false
        const visibilityState = data.reduce((acc, _, index) => {
          acc[index] = false;
          return acc;
        }, {});
        setVisiblePasswords(visibilityState);
      });
  }, [fetchData]);

  const togglePasswordVisibility = (index) => {
    setVisiblePasswords((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  function addAdmin() {
    if (addUsername === "" || addPassword === "") {
      setAddError("Please fill in all fields");
      return;
    }

    const api = `${process.env.REACT_APP_API_URL}/addAdmin`;
    const postData = {
      username: addUsername,
      password: addPassword,
    };

    fetch(api, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message === "exists") {
          setAddError("Username already exists");
          return
        }
        if (data.message === "added") {
          setAddVisible(false);
          setAddError("");
          setAddPassword("");
          setAddUsername("");
          setFetchData(!fetchData);
          return
        } else {
          setAddError(data.message);
          return
        }
      });
  }

  function closeAddPopup() {
    setAddVisible(false);
    setAddPassword("");
    setAddUsername("");
    setAddError("");
  }

  function closeEditPopup() {
    setEditVisible(false);
    setEditPassword("");
    setEditUsername("");
    setEditError("");
  }

  function handleEditClick(username) {
    setEditUsername(username);
    setEditVisible(true);
  }

  function editPasswordCall() {
    if (editPassword === "") {
      setEditError("Please fill in all fields");
      return;
    }

    const api = `${process.env.REACT_APP_API_URL}/updateAdmin`;
    const postData = {
      username: editUsername,
      password: editPassword,
    };

    fetch(api, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message === "updated") {
          setEditVisible(false);
          setEditError("");
          setFetchData(!fetchData);
          setEditUsername("");
          setEditPassword("");
        } else {
          setEditError(data.message);
        }
      });
  }

  function handleAdminDelete(username) {
    const confirmation = window.confirm(`Are you sure you want to delete ${username}?`);

    if (confirmation) {
      const api = `${process.env.REACT_APP_API_URL}/deleteAdmin`;
      const postData = {
        username: username,
      };

      fetch(api, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.message === "deleted") {
            setFetchData(!fetchData);
          }
        });
    }
  }

  return (
    <div>
      {addVisible && (
        <div className={s.popupWrapper}>
          <div className={s.popupInnerWrapper}>
            <div className={s.popup}>
              <button className={s.close} onClick={closeAddPopup}>
                Close
              </button>
              <p className={s.titlePopup}>Add Admin</p>
              <div>
                <FaUser />
                <input onChange={(e) => setAddUsername(e.target.value)} type="text" placeholder="Username" />
              </div>
              <div>
                <FaLock />
                <input onChange={(e) => setAddPassword(e.target.value)} type="text" placeholder="Password" />
              </div>
              <button onClick={addAdmin}>Add Admin</button>
              <p className={s.error}>{addError}</p>
            </div>
          </div>
        </div>
      )}

      {editVisible && (
        <div className={s.popupWrapper}>
          <div className={s.popupInnerWrapper}>
            <div className={s.popup}>
              <button className={s.close} onClick={closeEditPopup}>
                Close
              </button>
              <p className={s.titlePopup}>Edit {editUsername} password</p>
              <div>
                <FaLock />
                <input onChange={(e) => setEditPassword(e.target.value)} type="text" placeholder="Password" />
              </div>
              <button onClick={editPasswordCall}>Edit</button>
              <p className={s.error}>{editError}</p>
            </div>
          </div>
        </div>
      )}

      <p className={s.title}>Admin Users</p>

      <div className={s.addDiv}>
        <IoPersonAddSharp size={25} />
        <button className={s.addAdmin} onClick={() => setAddVisible(true)}>
          Add Admin
        </button>
      </div>

      {admins.map((admin, index) => (
        <div className={s.row} key={index}>
          <p>{admin.username}</p>
          <div className={s.passwordDiv}>
            <p id={`password${index}`}>{visiblePasswords[index] ? admin.password : "••••••••"}</p>
            {visiblePasswords[index] ? (
              <FaRegEye className={s.eye} onClick={() => togglePasswordVisibility(index)} size={20} />
            ) : (
              <FaEyeSlash className={s.eye} onClick={() => togglePasswordVisibility(index)} size={20} />
            )}
          </div>
          <button className={s.edit} onClick={() => handleEditClick(admin.username)}>
            Edit password
          </button>
          <button className={s.delete} onClick={() => handleAdminDelete(admin.username)}>
            Delete
          </button>
        </div>
      ))}
    </div>
  );
};

export default AdminUsers;
