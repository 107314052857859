import React from "react";
import { useState, useEffect } from "react";
import s from "../style/admin.module.css";
import Trips from "../components/admin/Trips";
import HomeTexts from "../components/admin/HomeTexts";
import { FaUser } from "react-icons/fa";
import { FaLock } from "react-icons/fa";
import { RxHamburgerMenu } from "react-icons/rx";
import Destinations from "../components/admin/Destinations";
import Users from "../components/admin/Users";
import AdminUsers from "../components/admin/AdminUsers";
import Interests from "../components/admin/Interests";
import Pages from "../components/admin/Pages";

export const Admin = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [selectedType, setSelectedType] = useState("Trips");
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 1100) {
      setIsMobile(true);
    }
  }, []);

  function handleUsernameChange(e) {
    setUsername(e.target.value);
  }
  function handlePasswordChange(e) {
    setPassword(e.target.value);
  }

  useEffect(() => {
    const adisin = localStorage.getItem("adisin");
    if (adisin === "t") {
      setLoggedIn(true);
    }
  }, []);

  function login() {
    const api = `${process.env.REACT_APP_API_URL}/loginAdmin`;
    const postData = {
      username: username,
      password: password,
    };

    fetch(api, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message === "Login success") {
          setLoggedIn(true);
          localStorage.setItem("adisin", "t");
        } else if (data.message === "error") {
          setError("Wrong username or password");
        }
      });
  }

  function logout() {
    setLoggedIn(false);
    localStorage.removeItem("adisin");
  }

  function hidePannel(event) {
    if (event.target.className === s.mobilePannelWrapper) {
      event.target.style.left = "-100%";
    }
  }

  function showPannel() {
    const pannel = document.querySelector(`.${s.mobilePannelWrapper}`);
    pannel.style.left = "0";
  }

  function selectType(e) {
    setSelectedType(e.target.textContent);
    const pannel = document.querySelector(`.${s.mobilePannelWrapper}`);
    pannel.style.left = "-100%";
  }

  return (
    <div>
      {!loggedIn && (
        <div className={s.loginWrapper}>
          <div className={s.loginContent}>
            <p className={s.loginTitle}>Login</p>
            <div>
              <FaUser />
              <input
                onChange={handleUsernameChange}
                type="text"
                placeholder="Username"
              />
            </div>
            <div>
              <FaLock />
              <input
                onChange={handlePasswordChange}
                type="password"
                placeholder="Password"
              />
            </div>
            <button onClick={login}>Log in</button>
            <p className={s.error}>{error}</p>
          </div>
        </div>
      )}

      {loggedIn && (
        <div className={s.wrapper}>
          {isMobile ? (
            <>
              <div>
                <RxHamburgerMenu
                  className={s.hambuerger}
                  onClick={showPannel}
                  size={35}
                />
              </div>

              <div className={s.mobilePannelWrapper} onClick={hidePannel}>
                <div className={s.pannel}>
                  <div>
                    <p onClick={selectType}>Trips</p>
                  </div>
                  <div>
                    <p onClick={selectType}>Destinations</p>
                  </div>
                  <div>
                    <p onClick={selectType}>Users</p>
                  </div>
                  <div>
                    <p onClick={selectType}>Home texts</p>
                  </div>
                  <div>
                    <p onClick={selectType}>Admin Users</p>
                  </div>
                  <div>
                    <p onClick={selectType}>Interests</p>
                  </div>
                  <div>
                    <p onClick={selectType}>Pages</p>
                  </div>

                  <button className={s.logout} onClick={logout}>
                    Log out
                  </button>
                </div>
              </div>
            </>
          ) : (
            <div className={`${s.pannel} ${s.desktopPannel}`}>
              <div>
                <p onClick={(e) => setSelectedType(e.target.textContent)}>
                  Trips
                </p>
              </div>
              <div>
                <p onClick={(e) => setSelectedType(e.target.textContent)}>
                  Destinations
                </p>
              </div>
              <div>
                <p onClick={(e) => setSelectedType(e.target.textContent)}>
                  Users
                </p>
              </div>
              <div>
                <p onClick={(e) => setSelectedType(e.target.textContent)}>
                  Home texts
                </p>
              </div>
              <div>
                <p onClick={(e) => setSelectedType(e.target.textContent)}>
                  Admin Users
                </p>
              </div>
              <div>
                <p onClick={(e) => setSelectedType(e.target.textContent)}>
                  Interests
                </p>
              </div>
              <div>
                <p onClick={(e) => setSelectedType(e.target.textContent)}>
                  Pages
                </p>
              </div>

              <button className={s.logout} onClick={logout}>
                Log out
              </button>
            </div>
          )}

          <div className={s.body}>
            {selectedType === "Trips" && (
              <div className={s.section}>
                <Trips />
              </div>
            )}

            {selectedType === "Users" && (
              <div className={s.section}>
                <Users />
              </div>
            )}

            {selectedType === "Home texts" && (
              <div className={s.section}>
                <HomeTexts />
              </div>
            )}

            {selectedType === "Destinations" && (
              <div className={s.section}>
                <Destinations />
              </div>
            )}

            {selectedType === "Admin Users" && (
              <div className={s.section}>
                <AdminUsers />
              </div>
            )}

            {selectedType === "Interests" && (
              <div className={s.section}>
                <Interests />
              </div>
            )}

            {selectedType === "Pages" && (
              <div className={s.section}>
                <Pages />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
