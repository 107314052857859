export default function dateToString(dateStr) {
  const date = new Date(dateStr);
  const formatter = new Intl.DateTimeFormat("en-US", {
    month: "long",
    day: "numeric",
  });

  const formattedDate = formatter.format(date);
  return formattedDate; 
}
