import React, { useState, useEffect } from "react";
import "../style/footerpages.css";
import Header from "../components/Header";
import Footer from "../components/Footer";

const AboutUs = () => {
  const [html, setHtml] = useState("");

  useEffect(() => {
    const api = `${process.env.REACT_APP_API_URL}/getSecondaryPage?page=about_us`;
    fetch(api)
      .then((response) => response.json())
      .then((data) => {
        setHtml(data[0].about_us);
      });
  });

  return (
    <div>
      <div className="hero">
        <Header />
        <p className="firstText">About Us</p>
        {/* <img className="backgroundImg" src={background} /> */}
      </div>

      <div className="body">
        <div dangerouslySetInnerHTML={{ __html: html }}></div>
      </div>

      <Footer />
    </div>
  );
};

export default AboutUs;
