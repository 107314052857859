import React from "react";
import logo from "../assets/svg/logo.svg";
import login from "../javascript/Login";
import logout from "../javascript/Logout";
import { useGoogleLogin } from "@react-oauth/google";
import { useState, useEffect } from "react";
import s from "../style/header.module.css";
import { LuUser2 } from "react-icons/lu";

const Header = ({ logFrom, isLogged, setIsLogged, isTour }) => {
  const [logStatus, setLogStatus] = useState(false);
  const [popupIsVisible, setPopupIsVisible] = useState(false);

  useEffect(() => {
    if (logFrom === "other") {
      setLogStatus(true);
      return;
    }
    if (setIsLogged) {
      setIsLogged(logStatus);
    }
    console.log(isLogged);
  }, [logStatus, isLogged, logFrom]);

  useEffect(() => {
    const id = localStorage.getItem("id");
    if (id) {
      setLogStatus(true);
    }
  }, []);

  const handleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      login(tokenResponse);
      setTimeout(() => {
        setLogStatus(true);
      }, 1000);
    },
  });

  const handleLogout = () => {
    logout();
    setTimeout(() => {
      localStorage.clear();
      setLogStatus(!logStatus);
      setPopupIsVisible(!popupIsVisible);
      setLogStatus(false);
    }, 1000);
  };

  return (
    <div className={`${s.headerDiv} ${isTour ? s.isTour : ""}`}>
      <img
        onClick={() => (window.location.href = "/")}
        src={logo}
        alt="logo"
        className={s.headerLogo}
      />
      <div>
        {logStatus ? (
          <div
            onClick={() => setPopupIsVisible(!popupIsVisible)}
            className={s.loggedIn}
          >
            <div className={s.userDiv}>
              <LuUser2 size={30} />
            </div>
            {popupIsVisible && (
              <div className={s.loggedInWrapper}>
                <p>{localStorage.getItem("name")}</p>
                <p onClick={() => (window.location.href = "/savedtrips")}>
                  My trips
                </p>
                <p onClick={() => handleLogout()}>Sign out</p>
              </div>
            )}
          </div>
        ) : (
          <>
            <button
              id="signin"
              onClick={() => {
                handleLogin();
              }}
              className={s.loginButton}
            >
              Sign in
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default Header;
