import React, { useEffect, useState, useRef } from "react";

const IncreasingNumber = ({ targetNumber }) => {
  const [currentNumber, setCurrentNumber] = useState(0);
  const numberRef = useRef(null);
  const observerRef = useRef(null);

  useEffect(() => {
    const increaseNumber = () => {
      let startNumber = 0;
      const duration = 1000; // 1 second duration
      const increment = Math.ceil(targetNumber / (duration / 10));

      const timer = setInterval(() => {
        startNumber += increment;
        if (startNumber >= targetNumber) {
          setCurrentNumber(targetNumber);
          clearInterval(timer);
        } else {
          setCurrentNumber(startNumber);
        }
      }, 10);
    };

    // Setting up IntersectionObserver
    observerRef.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        increaseNumber();
      }
    });

    if (numberRef.current) {
      observerRef.current.observe(numberRef.current);
    }

    return () => {
      if (observerRef.current && numberRef.current) {
        observerRef.current.unobserve(numberRef.current);
      }
    };
  }, [targetNumber]);

  return (
    <div ref={numberRef} style={{ fontSize: "48px", fontWeight: "bold" }}>
      {currentNumber}
    </div>
  );
};

export default IncreasingNumber;
