import React from "react";
import { useEffect, useState } from "react";
import s from "../../style/admin/interests.module.css";
import { FaRegTrashAlt } from "react-icons/fa";

const Interests = () => {
  const [interests, setInterests] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [newInterest, setNewInterest] = useState("");

  useEffect(() => {
    const api = `${process.env.REACT_APP_API_URL}/getInterests`;
    fetch(api)
      .then((response) => response.json())
      .then((data) => {
        setInterests(data);
      });
  }, [refresh]);

  function addInterest() {
    const api = `${process.env.REACT_APP_API_URL}/addInterest`;
    if (newInterest === "") {
      return;
    }
    const postData = {
      title: newInterest,
    };

    console.log(postData);
    fetch(api, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then(() => {
        setRefresh(!refresh);
      });
  }

  function deleteInterest(title) {
    const api = `${process.env.REACT_APP_API_URL}/deleteInterest`;
    const postData = {
      title: title,
    };
    fetch(api, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then(() => {
        setRefresh(!refresh);
      });
  }

  return (
    <div>
      <p className={s.title}>Interests</p>

      <div className={s.addDiv}>
        <input
          onChange={(e) => setNewInterest(e.target.value)}
          type="text"
          placeholder="Add new interest"
          className={s.input}
        />
        <button onClick={addInterest} className={s.addButton}>
          Add
        </button>
      </div>

      <div className={s.body}>
        {interests.map((interest) => {
          return (
            <div onClick={() => deleteInterest(interest.title)} key={interest.id}>
              <p>{interest.title}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Interests;
