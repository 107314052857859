import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import React from "react";
import Home from "./pages/Home";
import Tour from "./pages/Tour";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Admin } from "./pages/Admin";
import { SavedTrips } from "./pages/SavedTrips";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import AboutUs from "./pages/AboutUs";
import Terms from "./pages/Terms";

export default function Index() {
  return (
    <GoogleOAuthProvider clientId="221672019426-l9f9i9oqb9ullnhrsaqdrsk06scs4ics.apps.googleusercontent.com">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/tour" element={<Tour />}></Route>
          <Route path="/admin" element={<Admin />}></Route>
          <Route path="/savedtrips" element={<SavedTrips />}/>
          <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
          <Route path="/AboutUs" element={<AboutUs />}></Route>
          <Route path="/Terms" element={<Terms />}></Route>
        </Routes>
      </BrowserRouter>
    </GoogleOAuthProvider>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Index />);
