function triggerTourGeneration(
    uniqueID,
    destination,
    startDate,
    toDate,
    interests,
    mail,
    userName
  ) {
    let generateTourEndpoint = `${process.env.REACT_APP_API_URL}/generateTour?uniqueID=${uniqueID}&destination=${destination}&startDate=${startDate}&toDate=${toDate}&interests=${interests}&userMail=${mail}&userName=${userName}`;
    fetch(generateTourEndpoint)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      // .then((data) => {
      //   console.log(data);
      // })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  }
  
  export default triggerTourGeneration;
  