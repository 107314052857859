import React, { useEffect, useState } from "react";
import leaf from "../assets/svg/Vector.svg";
import s from "../style/tour.module.css";
import PropTypes from "prop-types";

const Leaf = ({ sustainable }) => {
  const [leafNum, setLeafNum] = useState(0);
  const [exists, setExists] = useState(true);

  useEffect(() => {
    if (sustainable != null) {
      let sustainableParsed = parseInt(sustainable.replace(/%/g, ""));
      let leafNum;
      if (sustainableParsed <= 20) {
        leafNum = 1;
      } else if (sustainableParsed <= 40) {
        leafNum = 2;
      } else if (sustainableParsed <= 60) {
        leafNum = 3;
      } else if (sustainableParsed <= 80) {
        leafNum = 4;
      } else if (sustainableParsed <= 100) {
        leafNum = 5;
      }
      setLeafNum(leafNum);
    } else {
      setExists(false);
    }
  }, [sustainable]);

  if (!exists) {
    return <div></div>;
  } else {
    const renderLeaves = (leafNum) => {
      const leaves = [];
      for (let i = 0; i < 5; i++) {
        leaves.push(
          <img
            key={i}
            className={i < leafNum ? `${s.leaf} ${s.leafGreen}` : s.leaf}
            src={leaf}
            alt={`Leaf ${i + 1}`}
          />
        );
      }
      return leaves;
    };

    return <div className={s.leafDiv}>{renderLeaves(leafNum)}</div>;
  }
};

Leaf.propTypes = {
  sustainable: PropTypes.string.isRequired,
};

export default Leaf;
