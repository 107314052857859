import React from "react";
import s from "../style/home.module.css";

const Sponsor = ({ image, url, special }) => {

  return (
    <img
      src={image}
      className={`${s.sponsorDivImage} ${special === "toyota" ? s.toyota : ""} ${special === "viator" ? s.viator : ""} ${special === "expedia" ? s.expedia : ""}`}
      onClick={() => window.open(url, "_blank")}
    />
  );
};

export default Sponsor;
