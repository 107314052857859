import React from "react";
import s from "../../style/admin/users.module.css";
import { useState, useEffect } from "react";
import Pagination from "../Pagination";

const Users = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;
  const [currentItems, setCurrentItems] = useState([]);
  const [amount, setAmount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const api = `${process.env.REACT_APP_API_URL}/getAmountUsers`;
    fetch(api)
      .then((response) => response.json())
      .then((data) => {
        setAmount(data[0].totalRows);
      });
  }, []);

  useEffect(() => {
    const totalPagesTemp = Math.ceil(amount / itemsPerPage);
    setTotalPages(totalPagesTemp);
  }, [amount]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/getUsers?page=${currentPage}&limit=${itemsPerPage}`
      );
      const result = await response.json();
      console.log(result);
      setCurrentItems(result);
    };
    fetchData();
  }, [currentPage, itemsPerPage]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div>
      <p className={s.title}>Users</p>
      <div className={s.head}>
        <p>Name</p>
        <p>Email</p>
        <p>Registered</p>
      </div>

      {currentItems.map((item) => (
        
        <div className={s.items} key={item.id}>
          <p>{item.name}</p>
          <p>{item.email}</p>
          <p>{item["wsdate"].split("T")[0]}</p>
        </div>
      ))}

      <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
    </div>
  );
};

export default Users;
