import React, { useState, useEffect } from "react";
import "../style/footerpages.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import background from "../assets/svg/WEB Hero.svg";

const PrivacyPolicy = () => {
  const [html, setHtml] = useState("");

  useEffect(() => {
    const api = `${process.env.REACT_APP_API_URL}/getSecondaryPage?page=privacy_policy`;
    fetch(api)
      .then((response) => response.json())
      .then((data) => {
        setHtml(data[0].privacy_policy);
      });
  });

  return (
    <div>
      <div className="hero">
        <Header />
        <p className="firstText">Privacy Policy</p>
        {/* <img className="backgroundImg" src={background} /> */}
      </div>

      <div className="body">
        <div dangerouslySetInnerHTML={{ __html: html }}></div>
      </div>

      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
