import { TypeAnimation } from "react-type-animation";
import TourLoaderAnimation from "../assets/svg/Loader.svg";
import s from "../style/tourloader.module.css";
import React, { useEffect, useState } from "react";

const TourLoader = ({ text }) => {
  const [destination, setDestination] = useState("");

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const dest = searchParams.get("destination");
    if (dest) {
      setDestination(dest);
    }
  }, []);

  return (
    <div className={s.wrapper}>
      <img src={TourLoaderAnimation} className={s.lottie} alt="Loading animation" />
      {destination && (
        <TypeAnimation
          className={s.text}
          sequence={[`${text} ${destination}`, 1000]}
          speed={50}
          style={{
            fontSize: "2em",
            color: "#35B4B7",
            fontWeight: "bold",
            textAlign: "center",
          }}
          repeat={Infinity}
        />
      )}
    </div>
  );
};

export default TourLoader;
